import React from "react"

const PageContent = () => {
  return (
    <div className="container">
      <div className="page-content">
        <p>
          Carbon steel and X grade linepipe make up a large part of all piping
          system components. Inductabend has the ability to bend all of the
          material groups used across all of the piping system specifications.
        </p>
        <p>Material specifications we frequently bend are:</p>
        <ul>
          <li>
            API Specification 5L – Grade B, X42, X52, X65, X70 PSL1 and PSL2 for
            gas transmission pipelines
          </li>
          <li>ASTM A106/A106M – all grades</li>
          <li>ASTM A333/A333M – all grades</li>
          <li>ASTM A335/A335M – all grades</li>
        </ul>
      </div>
    </div>
  )
}

export default PageContent
