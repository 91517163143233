import React from "react"

import Layout from "../../components/layout"
import Hero from "../../components/hero"
import PageContent from "../../page-views/materials/carbon-steel-and-x-grade-linepipe"
import BgImage from "../../assets/images/2017/09/perth-kalgoorlie-water-supply-pipeline-slide.jpg"

const CarbonSteelPage = () => {
  const title = "Carbon Steel and X Grade Linepipe"
  const content =
    "Mining slurry, gas transmission, oil, fuel and process piping"

  return (
    <Layout>
      <Hero title={title} content={content} bgImage={BgImage} />
      <PageContent />
    </Layout>
  )
}

export default CarbonSteelPage
